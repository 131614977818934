<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="red darken-2 white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete profile
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <p class="text-overline mb-0">Name</p>
                            <p class="mb-0 pb-0">
                                {{ user.display_name }}
                            </p>
                            <p class="text-overline mb-0 mt-2">Email</p>
                            <p class="mb-0 pb-0">
                                {{ user.email }}
                            </p>

                            <template v-if="enterpriseList">
                            <p class="text-overline mb-0 mt-2">Enterprises</p>
                            <p class="mb-0 pb-0" v-if="enterpriseList.length === 0">
                                No enterprises.
                            </p>
                            <p class="mb-0 pb-0" v-if="enterpriseList.length > 0">
                                You are currently linked to {{ enterpriseList.length }} enterprises. You must delete, transfer, or unlink your profile from each enterprise before you can delete your profile.
                            </p>
                            <v-list dense nav v-if="enterpriseList.length > 0">
                                <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickEnterprise(item.id)">
                                    <v-list-item-content class="text-start">
                                        {{ item.name }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            </template>

                            <template v-if="domainList">
                            <p class="text-overline mb-0 mt-4">Domains ({{ domainList.length }})</p>
                            <p class="mb-0 pb-0">
                                <v-chip :v-for="(domain, idx) in domainList" :key="idx">{{ domain.name }}</v-chip>
                            </p>
                            </template>

                            <template v-if="enterpriseList && enterpriseList.length === 0">
                                <p class="text-overline mb-0 mt-4">Confirmation</p>

                                <p>Tap on the button below to delete this profile and associated data.</p>

                                <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete my profile and all associated data." class="mt-0"></v-checkbox>
                                <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" label="I understand that some of my personal information may be retained internally for some time after deleting my profile, including this request to delete my profile, and that Cryptium will delete or anonymize the remaining information at the earliest opportunity." class="mt-0"></v-checkbox>
                                <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" label="I understand this action is not reversible, and that customer support will not be able to help me recover the data." class="mt-0"></v-checkbox>
                                <v-checkbox color="amber darken-2" v-model="deleteConfirmation4" label="I hereby disclaim any interest in this profile and any benefits that are associated to this profile, including but not limited to participation in any special programs and eligibility for discounts." class="mt-0"></v-checkbox>

                                <v-alert type="warning" border="left" dense>
                                    <template #prepend>
                                        <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                    </template>
                                    <p class="font-weight-bold ml-4 mb-0">This action is immediate and not reversible.</p>
                                    <p class="font-weight-light ml-4 mb-0">If you change your mind, you will need to create a new profile.</p>
                                </v-alert>

                                <v-btn color="red" class="white--text" @click="deleteProfile" :disabled="!isDeleteProfileConfirmed">Delete Profile</v-btn>
                            </template>
                        </v-card-text>
                    </v-card>

                    <!-- <v-expansion-panels class="mt-8 mb-6" v-if="isPermitServiceAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>Tap on the button below to delete this profile, including all domains, DNS records, and other related data.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteProfileImmediately">Delete Enterprise</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        enterpriseList: null,
        domainList: null,
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
        deleteConfirmation3: false,
        deleteConfirmation4: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isDeleteProfileConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2 && this.deleteConfirmation3 && this.deleteConfirmation4;
        },
    },
    watch: {
        focus() {
            this.loadEnterpriseList();
        },
    },
    methods: {
        async loadEnterpriseList() {
            try {
                this.$store.commit('loading', { loadEnterpriseList: true });
                const response = await this.$client.user(this.session.userId).self.getEnterpriseList();
                if (response?.list) {
                    this.enterpriseList = response.list;
                }
            } catch (err) {
                console.error('loadEnterpriseList failed', err);
            } finally {
                this.$store.commit('loading', { loadEnterpriseList: false });
            }
        },
        onClickEnterprise(enterpriseId) {
            this.$router.push({ name: 'enterprise-dashboard', params: { enterpriseId } });
        },
        async deleteProfile() {
            try {
                this.$store.commit('loading', { deleteProfile: true });
                const response = await this.$client.user(this.user.id).self.delete();
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted profile' });
                    // NOTE: on successful deletion of user record, server also deletes the session, so we just need to refresh
                    await this.$store.dispatch('refresh');
                    this.$router.push({ name: 'front' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete profile', message: this.$route.params.enterpriseId });
                }
            } catch (err) {
                console.error('failed to delete profile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete profile', message: this.$route.params.enterpriseId });
            } finally {
                this.$store.commit('loading', { deleteProfile: false });
            }
        },
        /**
         * Service admin can delete the user profile and data immediately (TODO: not implemented yet)
         */
        /*
        async deleteProfileImmediately() {
            try {
                this.$store.commit('loading', { deleteProfileImmediately: true });
                const response = this.$client.main().service.deleteProfile({ enterpriseId: this.$route.params.enterpriseId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted enterprise', message: this.$route.params.enterpriseId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete enterprise', message: this.$route.params.enterpriseId });
                }
            } catch (err) {
                console.error('failed to delete enterprise', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete enterprise', message: this.$route.params.enterpriseId });
            } finally {
                this.$store.commit('loading', { deleteProfileImmediately: false });
            }
        },
        */
    },
    mounted() {
        this.loadEnterpriseList();
        // this.loadDomainList();
    },
};
</script>
